import React from "react"

import Layout from "../components/Layout"

// @ts-ignore
import notFound from "../images/not_found.svg"

const Success = () => (
  <Layout title="Success">
    <div className="max-w-xl mx-auto text-center my-24 p-10">
      <h1 className="text-3xl">Oeps, deze pagina bestaat niet.</h1>
      <p className="lead">Heeft u de correcte link ingevoerd?</p>
      <img src={notFound} className="mt-16" />
    </div>
  </Layout>
)

export default Success
